body {
  font-family: "Roboto", sans-serif;
}

main {
  overflow: hidden;
}

h2 {
  font-size: 69px;
  font-weight: 700;
}

h2 span {
  color: #01d8ff;
}

h4 {
  font-size: 50px;
  font-weight: 900;
}

p,
ul {
  margin-bottom: 0px;
}

a {
  color: black;
  text-decoration: none;
  transition: 0.6s;
}

a:hover {
  color: black;
}

p {
  font-size: 23px;
}

.zoom {
  overflow: hidden;
}

.zoom img {
  transition: 0.6s;
}

.zoom img:hover {
  transform: scale(1.04);
}

.dot {
  font-size: 1.25em;
  line-height: 0px;
  animation-delay: 1.5s;
  animation-iteration-count: 5;
}

header {
  position: fixed;
  top: 0px;
  background-color: white;
  z-index: 1000;
  width: 100%;
}

header nav .nav-logo {
  display: flex;
  align-items: center;
  margin: 40px auto;
  transition: 1s;
}

header nav .menu-container {
  height: fit-content;
  margin: auto;
}

header nav ul {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

header nav li {
  list-style: none;
  display: inline-block;
  width: 160px;
  text-align: center;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 15px;
  font-size: 14px;
  height: 41px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

header nav li a:hover {
  color: grey;
}

header nav li.active::after {
  content: "";
  position: absolute;
  width: 80px;
  left: 50%;
  transform: translateX(-50%);
  height: 4px;
  background-color: #00aeff;
  top: 0px;
}

header nav .menu-container.open {
  height: calc(100vh - calc(100% + 3px));
}

header nav .menu-container.open .searchbar {
  display: block;
}

header nav .menu-container.open ul {
  display: block;
}

header nav #burger-container {
  position: relative;
  margin-left: auto;
  margin-right: 0px;
  display: flex;
  justify-content: end;
}

header nav #burger-container button {
  width: auto;
  display: block;
  background: 0 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-right: 0px;
  cursor: pointer;
}

header nav #burger-container button span {
  background: -webkit-linear-gradient(350deg, #e07051, #7182b7);
  display: block;
  width: 35px;
  height: 2px;
  margin-bottom: 8px;
  position: relative;
  top: 4px;
  -webkit-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

header nav #burger-container.open span:nth-child(1) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 19px;
}

header nav #burger-container.open span:nth-child(2),
header nav #burger-container.open span:nth-child(3) {
  width: 0;
  opacity: 0;
}

header nav #burger-container.open span:nth-child(4) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -11px;
}

.banner-bg {
  position: absolute;
  margin-top: 135px;
  height: calc(100vh - 135px);
  top: 0px;
  right: 0px;
}

.banner-bg img {
  object-fit: cover;
  height: 100%;
}

.banner-top {
  margin-top: 135px;
  height: calc(100vh - 135px);
  display: flex;
  align-items: center;
}

.banner-top h1 {
  font-size: 96px;
  font-weight: 500;
  letter-spacing: -5px;
  line-height: 1.14;
}

.banner-top h1 .dot {
  background: transparent;
  color: #7581b3;
  font-size: 1.34em;
}

.banner-top h1 span {
  background: -webkit-linear-gradient(1deg, #e07051, #7182b7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.our-specialization {
  padding: 123px 15px 38px 15px;
}

.our-specialization p span {
  text-transform: uppercase;
  font-weight: 700;
}

.our-specialization h2 {
  font-size: 70px;
  margin-bottom: 30px;
}

.our-specialization h2 span {
  color: #00aeff;
}

.our-specialization .specialization-wrapper {
  padding: 50px 35px 50px 35px;
  position: relative;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.our-specialization .specialization-wrapper:hover .specialization-bg {
  opacity: 0.63;
}

.our-specialization .specialization-wrapper:hover:nth-of-type(3) .specialization-bg {
  opacity: 0.43;
}

.our-specialization .specialization-wrapper:hover .specialization-content {
  transform: scale(1.015);
}

.our-specialization .specialization-wrapper .specialization-content {
  height: 100%;
  padding: 30px 20px 70px;
  transition: 0.2s;
}

.our-specialization .specialization-wrapper .specialization-content > p {
  margin-bottom: 75px;
  text-transform: lowercase;
  font-weight: 400;
}

.our-specialization .specialization-wrapper p {
  z-index: 1;
  position: relative;
}

.our-specialization .specialization-wrapper .specialization-bg {
  opacity: 0.7;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  transition: 0.2s;
}

.our-specialization .specialization-wrapper:first-of-type {
  background-image: url("/assets/img/specialization1.png");
}

.our-specialization .specialization-wrapper:first-of-type .specialization-bg {
  background-color: #152d5f;
}

.our-specialization .specialization-wrapper:nth-child(2) {
  z-index: 2;
  background-image: url("/assets/img/specialization2.png");
  background-position: bottom;
}

.our-specialization .specialization-wrapper:nth-child(2) .specialization-bg {
  background-color: #00150a;
}

.our-specialization .specialization-wrapper:nth-of-type(3) {
  background-image: url("/assets/img/specialization3.png");
}

.our-specialization .specialization-wrapper:nth-of-type(3) .specialization-bg {
  background-color: #5a2400;
  opacity: 0.5;
}

.our-specialization .specialization-wrapper p {
  color: white;
  text-transform: uppercase;
  font-weight: 700;
}

section {
  background-color: #35283c;
  padding-top: 90px;
  padding-bottom: 130px;
}

section .mail-us h2 {
  color: #01d8ff;
  margin-bottom: 65px;
  font-size: 70px;
  font-weight: 500;
}

section .mail-us p {
  color: #efd2ff;
}

section .mail-us .mail-us-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

section .mail-us .mail-us-text p:last-of-type {
  margin-top: 30px;
  font-weight: 700;
}

section .mail-us .btn {
  margin-top: 15px;
  background-color: #00aeff;
  width: 400px;
  height: 80px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  font-size: 29px;
  font-style: italic;
  transition: 0.2s;
}

section .mail-us .btn:hover {
  background-color: #e99600;
}

.our-clients {
  padding-bottom: 40px;
  padding-left: 0px;
  padding-right: 0px;
}

.our-clients .our-clients-header {
  background-image: url("/assets/img/kysection.png");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 90px;
}

.our-clients h2 {
  margin-bottom: 40px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
}

.our-clients p {
  padding-bottom: 100px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
}

.our-clients .client-img {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.our-clients .client-img img {
  max-width: 100%;
  object-fit: contain;
}

.last-projects {
  margin-bottom: 40px;
}

.last-projects p {
  margin-top: 35px;
  margin-bottom: 45px;
}

.last-projects .zoom {
  margin-bottom: 40px;
}

.last-projects .last-big-img .radius {
  border-bottom-left-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.last-projects .last-small-left {
  padding-right: 20px;
}

.last-projects .last-small-right {
  padding-left: 20px;
}

.padding-thing {
  position: relative;
  height: 0px;
  padding-bottom: 46.41%;
  width: 100%;
  display: inherit;
}

.padding-thing > * {
  position: absolute;
  height: 100%;
  width: 100%;
}

.padding-thing > * img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.last-projects .padding-thing,
.img-wrapper .padding-thing {
  padding-bottom: 52.9595015576324%;
}

.portfolio.portfolio-section {
  padding-top: 70px;
}

.portfolio .portfolio-img .img-right-down {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.portfolio .portfolio-img .img-middle-down {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.portfolio .portfolio-img .img-middle-down:hover {
  transform: translateX(-50%) scale(1.04);
}

.portfolio .portfolio-img .img-middle-top {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.portfolio .portfolio-img .img-middle-top:hover {
  transform: translateX(-50%) scale(1.04);
}

.portfolio .portfolio-img .img-left-top {
  position: absolute;
  top: 15px;
  left: 20px;
}

.portfolio .portfolio-img .img-left-middle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}

.portfolio .portfolio-img .img-left-middle:hover {
  transform: translateY(-50%) scale(1.04);
}

.portfolio .portfolio-img .img-left-down {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.portfolio p {
  margin-top: 35px;
  margin-bottom: 45px;
}

.portfolio .img-wrapper p {
  margin: 0px;
  font-size: 16px;
  padding: 14px 25px 10px 12px;
  margin-bottom: 30px;
}

.portfolio .img-wrapper .portfolio-client-link p:first-of-type {
  background-color: #14172e;
  color: white;
  display: inline-block;
  min-width: 185px;
  margin-bottom: 0px;
}

.portfolio .img-wrapper .portfolio-client-link p:first-of-type span {
  font-weight: 700;
  text-transform: uppercase;
}

.portfolio .img-wrapper .portfolio-client-link .get-more {
  display: inline-block;
  min-width: 185px;
  font-size: 16px;
  padding: 14px 25px 10px 12px;
  height: 100%;
  background-color: #fceb33;
  position: relative;
  transition: 0.3s;
}

.portfolio .img-wrapper .portfolio-client-link .get-more::after {
  position: absolute;
  content: "";
  background-image: url("../img/right-arrow.png");
  width: 15px;
  height: 15px;
  right: 10px;
  top: 54%;
  transform: translateY(-50%);
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.3s;
}

.portfolio .img-wrapper .portfolio-client-link .get-more:hover {
  filter: brightness(0.95);
}

.portfolio .img-item-absolute {
  position: absolute;
  height: 100%;
  left: 50%;
}

.portfolio .img-item-absolute.img-item-absolute-left {
  left: 0%;
}

.portfolio .img-item-absolute a {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.portfolio .img-item-absolute a .img-wrapper-content {
  height: 100%;
}

.portfolio .img-item-absolute a .img-wrapper-content img:first-of-type {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.portfolio .img-item-absolute > div {
  height: 50%;
  width: 100%;
}

.portfolio .portfolio-big-right {
  transform: translateX(100%);
}

.portfolio .tarczynski .img-left-top {
  top: 0px;
}

footer {
  background-color: #e0d6f7;
  padding-top: 64px;
  padding-bottom: 80px;
  margin-top: 20px;
  overflow: hidden;
}

footer a:hover {
  color: #e99600;
}

footer h2 {
  margin-bottom: 40px;
}

footer .footer-item p:first-of-type {
  font-weight: 700;
  margin-top: 30px;
}

footer p {
  line-height: 1.2;
}

footer .footer-content {
  z-index: 1;
}

footer .footer-img {
  position: absolute;
  z-index: 0;
  top: calc(0% - 65px);
  right: 26%;
  max-height: 100%;
}

footer .footer-img img {
  width: 154%;
}

.banner-bg-portfolio {
  width: 100%;
}

.banner-bg-portfolio img {
  width: 100%;
}

.banner-top.portfolio {
  display: block;
}

.banner-top.portfolio h1,
.banner-top.portfolio .h1 {
  padding-top: 60px;
  color: white;
  width: 200px;
  position: relative;
  font-size: 50px;
  letter-spacing: 0;
  font-weight: 900;
  z-index: 1;
}

.banner-top.portfolio h1:first-of-type,
.banner-top.portfolio .h1:first-of-type {
  text-transform: uppercase;
  font-weight: 400;
}

.banner-top.portfolio .h1 {
  margin-bottom: 0px;
}

.banner-top.portfolio h1 {
  color: white;
  display: inline-block;
  background: transparent;
  z-index: 1;
  padding-top: 50px;
  -webkit-text-fill-color: white;
}

.banner-top.portfolio h1:last-of-type {
  position: relative;
  padding-top: 0;
  margin-top: 50px;
  font-weight: 900;
  text-transform: initial;
}

.banner-top.portfolio h1:last-of-type::after {
  content: "";
  position: absolute;
  top: 12px;
  left: -33px;
  height: 7px;
  width: 20px;
  background-color: #e99600;
}

.portfolio-main {
  padding-top: 80px;
  padding-bottom: 80px;
}

.portfolio-main .portfolio-main-header {
  position: relative;
  top: 40px;
}

.portfolio-main .portfolio-main-header::after {
  content: "";
  position: absolute;
  top: -40px;
  left: 14px;
  height: 10px;
  width: 35px;
  background-color: #e99600;
}

.portfolio-main .portfolio-main-header h2 {
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: -2px;
  line-height: 64px;
}

.photo-section .zoom {
  height: 100%;
}

.photo-section .zoom img:hover {
  transform: scale(1.02);
}

.photo-section .photo-section-img {
  max-height: 505px;
}

.photo-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.text {
  padding-top: 70px;
  padding-bottom: 70px;
}

.text p {
  margin-bottom: 20px;
}

.text h4 {
  margin-bottom: 40px;
}

.photo-section2 .img-container img {
  width: 100%;
}

.portfolio-logo img {
  max-width: 215px;
}

@media (max-width: 1399.98px) {
  .our-specialization .specialization-wrapper .specialization-bg-2 {
    left: 0px;
    right: 0px;
    bottom: 56px;
  }
  .last-projects .zoom {
    margin-bottom: 20px;
  }
  .last-projects .last-small-left {
    padding-right: 10px;
  }
  .last-projects .last-small-right {
    padding-left: 10px;
  }
  footer .footer-img {
    top: 0%;
  }
}

@media (max-width: 1199.98px) {
  header nav li {
    width: 136px;
  }
  .our-specialization .specialization-wrapper {
    width: 33.3%;
  }
  .our-specialization .specialization-wrapper .specialization-content {
    padding: 16px 20px;
  }
  .our-specialization .specialization-wrapper .specialization-content > p {
    margin-bottom: 37px;
  }
  .our-specialization .specialization-wrapper .specialization-bg-2 {
    left: 0px;
    right: 0px;
  }
  footer .footer-img {
    top: 20%;
  }
}

@media (max-width: 991.98px) {
  header nav .nav-logo {
    margin: 18px 0;
  }
  header nav .menu-container {
    display: none;
    padding: 0px;
  }
  header nav .menu-container .menu-list {
    align-items: flex-start;
    background-color: white;
    padding: 10px 10px;
    width: 100%;
  }
  header nav .menu-container li {
    padding: 10px 0px;
    justify-content: start;
    width: 100%;
    font-weight: 500;
  }
  header nav .menu-container li:hover a {
    color: #e99600;
  }
  header nav .menu-container li a {
    width: 100%;
    text-align: start;
    display: inline-block;
  }
  header nav .menu-container li.active::after {
    content: "";
    position: absolute;
    width: 120px;
    height: 2px;
    background: -webkit-linear-gradient(1deg, #e07051, #7182b7);
    bottom: -3px;
    left: 0px;
    top: initial;
    transform: translate(0px);
  }
  header nav .menu-container.open {
    display: flex;
    order: 1;
    align-items: flex-end;
  }
  header nav .menu-container.open ul {
    display: flex;
    flex-direction: column;
  }
  .banner-top {
    margin-top: 77px;
    height: calc(100vh - 77px);
  }
  .banner-top h1 {
    font-size: 85px;
    letter-spacing: -4px;
    line-height: 1.05;
  }
  .banner-bg {
    margin-top: 77px;
    height: calc((100vh - 77px));
    overflow: hidden;
  }
  .banner-bg img {
    width: 147%;
    object-fit: fill;
    object-position: center;
    transform: translateX(-5%);
  }
  .our-specialization {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .our-specialization h2 {
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  .our-specialization .specialization-wrapper {
    width: 100%;
  }
  section .mail-us .mail-us-text {
    margin-top: 60px;
  }
  section .mail-us .btn {
    margin-top: 40px;
  }
  .last-projects .padding-thing {
    margin-bottom: 12px;
  }
  .last-projects .last-small-left {
    padding-right: 6px;
  }
  .last-projects .last-small-right {
    padding-left: 6px;
  }
  .portfolio .img-wrapper p {
    margin-bottom: 10px;
    font-size: 11px;
    padding: 10px 16px 8px 10px;
  }
  .portfolio .img-wrapper .portfolio-client-link p:first-of-type {
    min-width: initial;
    width: initial;
  }
  .portfolio .img-wrapper .portfolio-client-link .get-more {
    min-width: initial;
    width: 135px;
  }
  .portfolio .img-wrapper .portfolio-client-link .get-more::after {
    width: 13px;
    height: 13px;
    right: 3px;
    top: 52%;
  }
  .portfolio .img-wrapper .portfolio-client-link p,
  .portfolio .img-wrapper .portfolio-client-link .get-more {
    font-size: 12px;
    padding: 10px 16px 8px 10px;
  }
  .portfolio .portfolio-img img:nth-child(2) {
    max-width: 20%;
  }
  footer .footer-img {
    top: 40%;
    right: 13%;
  }
  .portfolio-main .portfolio-main-header {
    margin-bottom: 70px;
  }
  .banner-bg-portfolio {
    width: 100%;
  }
  .banner-bg-portfolio img {
    width: 100%;
    object-fit: cover;
    transform: translateX(0%);
  }
}

@media (max-width: 767.98px) {
  .last-projects .zoom {
    margin-bottom: 12px;
  }
  .last-projects .last-small-left {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
  .last-projects .last-small-right {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
  .portfolio .img-wrapper a {
    margin-bottom: 0px !important;
  }
  .portfolio .img-wrapper p:first-of-type {
    width: 100%;
  }
  .portfolio .img-item-absolute {
    position: static;
  }
  .portfolio .portfolio-big-right {
    transform: translateX(0%);
  }
  footer .footer-img {
    top: 50%;
    right: 13%;
  }
}

@media (max-width: 575.98px) {
  .banner-top h1 {
    font-size: 50px;
    line-height: 1.1;
    letter-spacing: -2px;
  }
  .banner-bg img {
    width: 161%;
    transform: translateX(-8%);
  }
  h2 {
    font-size: 47px;
  }
  section .mail-us h2 {
    font-size: 47px;
  }
  section .mail-us .btn {
    width: 310px;
    font-size: 25px;
  }
  .our-specialization h2 {
    font-size: 47px;
  }
  .our-specialization p {
    font-size: 23px;
  }
  footer .footer-img {
    top: initial;
    right: 22%;
    bottom: 0%;
  }
  .portfolio-main .portfolio-main-header h2 {
    font-size: 50px;
  }
  .text h4 {
    font-size: 33px;
  }
}

@media (max-width: 360px) {
  .banner-top h1 {
    font-size: 50px;
  }
}

@media (min-width: 992px) {
  #burger-container button {
    display: none;
  }
  .menu-toggle {
    display: none;
  }
  .nav-logo.hidden {
    margin: 20px auto;
  }
}
