@import "variables.scss";
@import "forms.scss";

body {
  font-family: "Roboto", sans-serif;
}

main {
  overflow: hidden;
}

h2 {
  font-size: 69px;
  font-weight: 700;

  span {
    color: $quaternary-color;
  }
}

h4 {
  font-size: 50px;
  font-weight: 900;
}

p,
ul {
  margin-bottom: 0px;
}

a {
  color: black;
  text-decoration: none;
  transition: 0.6s;

  &:hover {
    color: black;
  }
}

p {
  font-size: 23px;
}

.zoom {
  overflow: hidden;

  img {
    transition: 0.6s;
  }

  & img:hover {
    transform: scale(1.04);
  }
}

.dot {
  font-size: 1.25em;
  line-height: 0px;
  animation-delay: 1.5s;
  animation-iteration-count: 5;
}

// < header
header {
  position: fixed;
  top: 0px;
  background-color: white;
  z-index: 1000;
  width: 100%;

  nav {
    // height: 135px;

    .nav-logo {
      display: flex;
      align-items: center;
      margin: 40px auto;
      transition: 1s;
    }

    .menu-container {
      height: fit-content;
      margin: auto;
    }

    ul {
      display: flex;
      align-items: flex-end;
      height: 100%;
    }

    li {
      list-style: none;
      display: inline-block;
      width: 160px;
      text-align: center;
      position: relative;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 15px;
      font-size: 14px;
      height: 41px;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      a:hover {
        color: grey;
      }

      &.active::after {
        content: "";
        position: absolute;
        width: 80px;
        left: 50%;
        transform: translateX(-50%);
        height: 4px;
        background-color: $primary-color;
        top: 0px;
      }
    }

    .menu-container.open {
      height: calc(100vh - calc(100% + 3px));

      .searchbar {
        display: block;
      }

      ul {
        display: block;
      }
    }

    #burger-container {
      position: relative;
      margin-left: auto;
      margin-right: 0px;
      display: flex;
      justify-content: end;

      button {
        width: auto;
        display: block;
        background: 0 0;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        margin-right: 0px;
        cursor: pointer;

        span {
          // background: grey;
          background: -webkit-linear-gradient(350deg, #e07051, #7182b7);
          display: block;
          width: 35px;
          height: 2px;
          margin-bottom: 8px;
          position: relative;
          top: 4px;
          -webkit-transition: all ease-in-out 0.4s;
          -o-transition: all ease-in-out 0.4s;
          transition: all ease-in-out 0.4s;
        }
      }
    }

    #burger-container.open {
      span:nth-child(1) {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 19px;
      }

      span:nth-child(2),
      span:nth-child(3) {
        width: 0;
        opacity: 0;
      }

      span:nth-child(4) {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -11px;
      }
    }
  }
}

// > header
// < banner-top
.banner-bg {
  // background-image: url('/assets/img/bannertop.png');
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: right;
  position: absolute;
  margin-top: 135px;
  height: calc(100vh - 135px);
  top: 0px;
  right: 0px;

  img {
    object-fit: cover;
    height: 100%;
  }
}

.banner-top {
  margin-top: 135px;
  height: calc(100vh - 135px);
  display: flex;
  align-items: center;

  h1 {
    font-size: 96px;
    font-weight: 500;
    letter-spacing: -5px;
    line-height: 1.14;

    .dot {
      background: transparent;
      color: #7581b3;
      font-size: 1.34em;
    }

    span {
      background: -webkit-linear-gradient(1deg, #e07051, #7182b7);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

// > banner-top
// < our-specialization
.our-specialization {
  padding: 123px 15px 38px 15px;

  p {
    span {
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  h2 {
    font-size: 70px;
    margin-bottom: 30px;

    span {
      color: $primary-color;
    }
  }

  .specialization-wrapper {
    padding: 50px 35px 50px 35px;
    position: relative;
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      .specialization-bg {
        opacity: 0.63;
      }

      &:nth-of-type(3) {
        .specialization-bg {
          opacity: 0.43;
        }
      }

      .specialization-content {
        transform: scale(1.015);
      }
    }

    .specialization-content {
      height: 100%;
      padding: 30px 20px 70px;
      transition: 0.2s;

      & > p {
        margin-bottom: 75px;
        text-transform: lowercase;
        font-weight: 400;
      }
    }

    p {
      z-index: 1;
      position: relative;
    }

    .specialization-bg {
      opacity: 0.7;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0%;
      left: 0%;
      transition: 0.2s;
    }

    &:first-of-type {
      background-image: url("/assets/img/specialization1.png");

      .specialization-bg {
        background-color: rgb(21, 45, 95);
      }
    }

    &:nth-child(2) {
      z-index: 2;
      background-image: url("/assets/img/specialization2.png");
      background-position: bottom;

      .specialization-bg {
        background-color: rgb(0, 21, 10);
      }
    }

    &:nth-of-type(3) {
      background-image: url("/assets/img/specialization3.png");

      .specialization-bg {
        background-color: rgb(90, 36, 0);
        opacity: 0.5;
      }
    }

    p {
      color: white;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}

// > our-specialization
// < mail-us
section {
  background-color: $tertiary-color;
  padding-top: 90px;
  padding-bottom: 130px;

  .mail-us {
    h2 {
      color: $quaternary-color;
      margin-bottom: 65px;
      font-size: 70px;
      font-weight: 500;
    }

    p {
      color: #efd2ff;
    }

    .mail-us-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p:last-of-type {
        margin-top: 30px;
        font-weight: 700;
      }
    }

    .btn {
      margin-top: 15px;
      background-color: $primary-color;
      width: 400px;
      height: 80px;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
      font-size: 29px;
      font-style: italic;
      transition: 0.2s;

      &:hover {
        background-color: $secondary-color;
      }
    }
  }
}

//  > mail-us
// < our-clients
.our-clients {
  padding-bottom: 40px;
  padding-left: 0px;
  padding-right: 0px;

  .our-clients-header {
    background-image: url("/assets/img/kysection.png");
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 90px;
  }

  h2 {
    margin-bottom: 40px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }

  p {
    padding-bottom: 100px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }

  .client-img {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      object-fit: contain;
    }
  }
}

// > our-clients
// < last-projects
.last-projects {
  margin-bottom: 40px;
  p {
    margin-top: 35px;
    margin-bottom: 45px;
  }
  .zoom {
    margin-bottom: 40px;
  }
  .last-big-img {
    .radius {
      border-bottom-left-radius: 14px;
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px;
    }
  }

  .last-small-left {
    padding-right: 20px;
  }

  .last-small-right {
    padding-left: 20px;
  }
}

.padding-thing {
  position: relative;
  height: 0px;
  padding-bottom: 46.41%;
  width: 100%;
  display: inherit;
  > * {
    position: absolute;
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
.last-projects .padding-thing,
.img-wrapper .padding-thing{
  padding-bottom: 52.9595015576324%;
}
// > last-projects
// < portfolio
.portfolio {
  &.portfolio-section {
    padding-top: 70px;
  }

  .portfolio-img {
    .img-right-down {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    .img-middle-down {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);

      &:hover {
        transform: translateX(-50%) scale(1.04);
      }
    }

    .img-middle-top {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);

      &:hover {
        transform: translateX(-50%) scale(1.04);
      }
    }

    .img-left-top {
      position: absolute;
      top: 15px;
      left: 20px;
    }

    .img-left-middle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;

      &:hover {
        transform: translateY(-50%) scale(1.04);
      }
    }

    .img-left-down {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }

  p {
    margin-top: 35px;
    margin-bottom: 45px;
  }

  .img-wrapper {
    p {
      margin: 0px;
      font-size: 16px;
      padding: 14px 25px 10px 12px;
      margin-bottom: 30px;
    }

    .portfolio-client-link {
      p:first-of-type {
        background-color: $senary-color;
        color: white;
        display: inline-block;
        min-width: 185px;
        margin-bottom: 0px;

        span {
          font-weight: 700;
          text-transform: uppercase;
        }
      }
      .get-more {
        display: inline-block;
        min-width: 185px;
        font-size: 16px;
        padding: 14px 25px 10px 12px;
        height: 100%;
        background-color: #fceb33;
        position: relative;
        transition: 0.3s;
        &::after {
          position: absolute;
          content: "";
          background-image: url("../img/right-arrow.png");
          width: 15px;
          height: 15px;
          right: 10px;
          top: 54%;
          transform: translateY(-50%);
          background-size: contain;
          background-repeat: no-repeat;
          transition: 0.3s;
        }
        &:hover {
          filter: brightness(0.95);
        }
      }
    }
  }

  .img-item-absolute {
    position: absolute;
    height: 100%;
    left: 50%;

    &.img-item-absolute-left {
      left: 0%;
    }

    a {
      display: inline-block;
      height: 100%;
      width: 100%;
      .img-wrapper-content {
        height: 100%;

        img:first-of-type {
          width: 100%;
          object-fit: cover;
          object-position: center;
          height: 100%;
        }
      }
    }

    & > div {
      height: 50%;
      width: 100%;
    }
  }

  .portfolio-big-right {
    transform: translateX(100%);
  }

  .tarczynski {
    .img-left-top {
      top: 0px;
    }
  }
}

// > portfolio
// < footer
footer {
  background-color: $quinary-color;
  padding-top: 64px;
  padding-bottom: 80px;
  margin-top: 20px;
  overflow: hidden;

  a:hover {
    color: $secondary-color;
  }

  h2 {
    margin-bottom: 40px;
  }

  .footer-item {
    p:first-of-type {
      font-weight: 700;
      margin-top: 30px;
    }
  }

  p {
    line-height: 1.2;
  }

  .footer-content {
    z-index: 1;
  }

  .footer-img {
    position: absolute;
    z-index: 0;
    top: calc(0% - 65px);
    right: 26%;
    max-height: 100%;

    img {
      width: 154%;
    }
  }
}

// > footer
// < portfolio subpage
.banner-bg-portfolio {
  width: 100%;

  img {
    width: 100%;
  }
}

.banner-top.portfolio {
  display: block;

  h1,
  .h1 {
    padding-top: 60px;
    color: white;
    width: 200px;
    position: relative;
    font-size: 50px;
    letter-spacing: 0;
    font-weight: 900;
    z-index: 1;

    &:first-of-type {
      text-transform: uppercase;
      font-weight: 400;
    }
  }

  .h1 {
    margin-bottom: 0px;
  }

  h1 {
    color: white;
    display: inline-block;
    background: transparent;
    z-index: 1;
    padding-top: 50px;
    // background-clip:
    -webkit-text-fill-color: white;

    &:last-of-type {
      position: relative;
      padding-top: 0;
      margin-top: 50px;
      font-weight: 900;
      text-transform: initial;

      &::after {
        content: "";
        position: absolute;
        top: 12px;
        left: -33px;
        height: 7px;
        width: 20px;
        background-color: $secondary-color;
      }
    }
  }
}

.portfolio-main {
  padding-top: 80px;
  padding-bottom: 80px;

  .portfolio-main-header {
    position: relative;
    top: 40px;

    &::after {
      content: "";
      position: absolute;
      top: -40px;
      left: 14px;
      height: 10px;
      width: 35px;
      background-color: $secondary-color;
    }

    h2 {
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: -2px;
      line-height: 64px;
    }
  }
}

.photo-section {
  .zoom {
    height: 100%;

    & img:hover {
      transform: scale(1.02);
    }
  }

  .photo-section-img {
    max-height: 505px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.text {
  padding-top: 70px;
  padding-bottom: 70px;

  p {
    margin-bottom: 20px;
  }

  h4 {
    margin-bottom: 40px;
  }
}

.photo-section2 {
  .img-container {
    img {
      width: 100%;
    }
  }
}

.portfolio-logo {
  img {
    max-width: 215px;
  }
}

// > portfolio subpage
@media (max-width: 1399.98px) {
  .our-specialization {
    .specialization-wrapper {
      .specialization-bg-2 {
        left: 0px;
        right: 0px;
        bottom: 56px;
      }
    }
  }

  .last-projects {
    .zoom {
      margin-bottom: 20px;
    }

    .last-small-left {
      padding-right: 10px;
    }

    .last-small-right {
      padding-left: 10px;
    }
  }

  footer {
    .footer-img {
      top: 0%;
    }
  }
}

@media (max-width: 1199.98px) {
  header {
    nav {
      li {
        width: 136px;
      }
    }
  }

  // .banner-bg {
  //     img {
  //         width: 100%;
  //         object-fit: contain;
  //     }
  // }

  .our-specialization {
    .specialization-wrapper {
      width: 33.3%;

      .specialization-content {
        padding: 16px 20px;

        & > p {
          margin-bottom: 37px;
        }
      }

      .specialization-bg-2 {
        left: 0px;
        right: 0px;
      }
    }
  }

  footer {
    .footer-img {
      top: 20%;
    }
  }
}

@media (max-width: 991.98px) {
  header {
    nav {
      .nav-logo {
        margin: 18px 0;
      }

      .menu-container {
        display: none;
        padding: 0px;

        .menu-list {
          align-items: flex-start;
          background-color: white;
          padding: 10px 10px;
          width: 100%;
        }

        li {
          padding: 10px 0px;
          justify-content: start;
          width: 100%;
          font-weight: 500;

          &:hover a {
            color: $secondary-color;
          }

          a {
            width: 100%;
            text-align: start;
            display: inline-block;
          }

          &.active::after {
            content: "";
            position: absolute;
            width: 120px;
            height: 2px;
            // background-color: #e99600;
            background: -webkit-linear-gradient(1deg, #e07051, #7182b7);
            bottom: -3px;
            left: 0px;
            top: initial;
            transform: translate(0px);
          }
        }

        &.open {
          display: flex;
          order: 1;
          align-items: flex-end;

          ul {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  .banner-top {
    margin-top: $navbar-mobile;
    height: calc(100vh - #{$navbar-mobile});

    h1 {
      font-size: 85px;
      letter-spacing: -4px;
      line-height: 1.05;
    }
  }

  .banner-bg {
    margin-top: $navbar-mobile;
    height: calc((100vh - #{$navbar-mobile}));
    // background-image: url(/assets/img/bannertop.png);
    // width: 147%;
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: center;
    // right: inherit;
    // left: -8%;
    overflow: hidden;

    img {
      width: 147%;
      object-fit: fill;
      object-position: center;
      transform: translateX(-5%);
    }
  }

  .our-specialization {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;

    h2 {
      padding-left: var(--bs-gutter-x, 0.75rem);
    }

    .specialization-wrapper {
      width: 100%;
    }
  }

  section {
    .mail-us {
      .mail-us-text {
        margin-top: 60px;
      }

      .btn {
        margin-top: 40px;
      }
    }
  }

  .last-projects {
    .padding-thing {
      margin-bottom: 12px;
    }

    .last-small-left {
      padding-right: 6px;
    }

    .last-small-right {
      padding-left: 6px;
    }
  }

  .portfolio {
    .img-wrapper {
      p {
        margin-bottom: 10px;
        font-size: 11px;
        padding: 10px 16px 8px 10px;
      }
      .portfolio-client-link {
        p:first-of-type {
          min-width: initial;
          width: initial;
        }
        .get-more {
          min-width: initial;
          width: 135px;
          &::after {
            width: 13px;
            height: 13px;
            right: 3px;
            top: 52%;
          }
        }
        p,
        .get-more {
          font-size: 12px;
          padding: 10px 16px 8px 10px;
        }
      }
    }

    .portfolio-img {
      img:nth-child(2) {
        max-width: 20%;
      }
    }
  }

  footer {
    .footer-img {
      top: 40%;
      right: 13%;
    }
  }

  .portfolio-main .portfolio-main-header {
    margin-bottom: 70px;
  }

  .banner-bg-portfolio {
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
      transform: translateX(0%);
    }
  }
}

@media (max-width: 767.98px) {
  .last-projects {
    .zoom {
      margin-bottom: 12px;
    }

    .last-small-left {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
    }

    .last-small-right {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
  }

  .portfolio {
    .img-wrapper {
      a {
        margin-bottom: 0px !important;
      }

      p:first-of-type {
        width: 100%;
      }
    }

    .img-item-absolute {
      position: static;
    }

    .portfolio-big-right {
      transform: translateX(0%);
    }
  }

  footer {
    .footer-img {
      top: 50%;
      right: 13%;
    }
  }
}

@media (max-width: 575.98px) {
  .banner-top {
    h1 {
      font-size: 50px;
      line-height: 1.1;
      letter-spacing: -2px;
    }
  }

  .banner-bg img {
    width: 161%;
    transform: translateX(-8%);
  }

  h2 {
    font-size: 47px;
  }

  section {
    .mail-us {
      h2 {
        font-size: 47px;
      }
    }
  }

  section .mail-us .btn {
    width: 310px;
    font-size: 25px;
  }

  .our-specialization {
    h2 {
      font-size: 47px;
    }

    p {
      font-size: 23px;
    }
  }

  footer {
    .footer-img {
      top: initial;
      right: 22%;
      bottom: 0%;
    }
  }

  .portfolio-main .portfolio-main-header {
    h2 {
      font-size: 50px;
    }
  }

  .text {
    h4 {
      font-size: 33px;
    }
  }
}

@media (max-width: 360px) {
  .banner-top {
    h1 {
      font-size: 50px;
    }
  }
}

@media (min-width: 992px) {
  #burger-container button {
    display: none;
  }

  .menu-toggle {
    display: none;
  }

  .nav-logo {
    &.hidden {
      margin: 20px auto;
    }
  }
}

@media (min-width: 1200px) {
}
